
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@use './wrollo-palette' as wrollo-palette;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$my-primary-palette: mat.define-palette(wrollo-palette.$wrollo-palette, 500);
$my-accent-palette: mat.define-palette(mat.$blue-grey-palette, 400);
$my-warn-palette: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
    color: (
        primary: $my-primary-palette,
        accent: $my-accent-palette,
        warn: $my-warn-palette,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

@include mat.all-component-themes($my-theme);

html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif
}
h1 {
  font-size: 1.5rem;
  color: wrollo-palette.$primary-color;
}
h2 {
  font-size: 1.25rem;
  color: wrollo-palette.$primary-color;
}
a {
  color: wrollo-palette.$primary-color;
}

.flex-row-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-left-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start
}

.flex-row-wrap-reverse {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.flex-row-space-between-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-col-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-col-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-col-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.bold{font-weight: bold}

.height-100{height: 100%;}

.width-100{width: 100%;}
.width-90{width: 90%;}
.width-85{width: 85%;}
.width-80{width: 80%;}
.width-75{width: 75%;}
.width-70{width: 70%;}
.width-66{width: 66%;}
.width-60{width: 60%;}
.width-50{width: 50%;}
.width-45{width: 45%;}
.width-40{width: 40%;}
.width-37{width: 37.5%;}
.width-33{width: 33%;}
.width-30{width: 30%;}
.width-25{width: 25%;}
.width-20{width: 20%;}
.width-15{width: 15%;}
.width-10{width: 10%;}

.horiz-padding{padding-left: 1em; padding-right: 1em;}
.horiz-margin{margin-left: 0.5em; margin-right: 0.5em;}
.horiz-margin-1x{margin-left: 1em; margin-right: 1em;}
.horiz-margin-2x{margin-left: 2em; margin-right: 2em;}
.horiz-margin-left{margin-left: 1em;}
.horiz-margin-right{margin-right: 1em;}
.vert-padding{padding-top: 1em; padding-bottom: 1em;}
.vert-padding-2x{padding-top: 2em; padding-bottom: 2em;}
.vert-margin{margin-top: 1em; margin-bottom: 1em;}
.vert-margin-2x{margin-top: 2em; margin-bottom: 2em;}
.vert-margin-top{margin-top: 0.5em;}
.vert-margin-bottom{margin-bottom: 0.5em;}

.text-align-center{text-align: center !important;}
.margin-center{margin-left: auto; margin-right: auto;}

.multiline-tooltip {
  white-space: pre-line;

  .mdc-tooltip__surface {
    text-align: left !important;
  }
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.mat-simple-snackbar {
  font-size: 1.2em;
  color: white;
}

.mat-mdc-standard-chip {
  background-color: wrollo-palette.$secondary-color !important;
  color: #311b92;
}

.mat-mdc-tab.mdc-tab {
  border-bottom:  0.5px solid wrollo-palette.$primary-color !important;
}

.snackbar-error {
  background-color: red;
}

.snackbar-success {
  background-color: green;
}

.snackbar-info {
  background-color: blue;
}

.info-icon {
  margin-left: 0.25rem;
}

.loading-overlay {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.no-table-data {
  padding: 1rem;
  text-align: center;
}

tr.table-row:hover {
  background: whitesmoke;
}

tr.table-row-selected {
  background: whitesmoke;
}

.center-table-header {
  .mat-sort-header-container {
    justify-content: center !important;
  }
}

.small-icon-btn {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role=img] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.white-btn {
  background-color: white !important;
  color: wrollo-palette.$primary-color !important;
}

.mat-mdc-card-header-text {
  width: 100%;
}
